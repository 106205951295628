import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactImageGallery from 'react-image-gallery';

import { propTypes } from '../../../util/types';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import {
  AspectRatioWrapper,
  Button,
  IconClose,
  IconArrowHead,
  ResponsiveImage,
} from '../../../components';

// Copied directly from
// `node_modules/react-image-gallery/styles/css/image-gallery.css`. The
// copied file is left unedited, and all the overrides are defined in
// the component CSS file below.
import './image-gallery.css';

import css from './ListingImageGallery.module.css';

const IMAGE_GALLERY_OPTIONS = {
  showPlayButton: false,
  disableThumbnailScroll: true,
};
const MAX_LANDSCAPE_ASPECT_RATIO = 2; // 2:1
const MAX_PORTRAIT_ASPECT_RATIO = 4 / 3;

const getFirstImageAspectRatio = (firstImage, scaledVariant) => {
  if (!firstImage) {
    return { aspectWidth: 1, aspectHeight: 1 };
  }

  const v = firstImage?.attributes?.variants?.[scaledVariant];
  const w = v?.width;
  const h = v?.height;
  const hasDimensions = !!w && !!h;
  const aspectRatio = w / h;

  // We keep the fractions separated as these are given to AspectRatioWrapper
  // which expects separate width and height
  return hasDimensions && aspectRatio >= MAX_LANDSCAPE_ASPECT_RATIO
    ? { aspectWidth: 2, aspectHeight: 1 }
    : hasDimensions && aspectRatio <= MAX_PORTRAIT_ASPECT_RATIO
    ? { aspectWidth: 4, aspectHeight: 3 }
    : hasDimensions
    ? { aspectWidth: w, aspectHeight: h }
    : { aspectWidth: 1, aspectHeight: 1 };
};

const ListingImageGallery = props => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const galleryRef = useRef(null);
  const { intl, rootClassName, className, images, imageVariants, thumbnailVariants } = props;
  const thumbVariants = thumbnailVariants || imageVariants;
  // imageVariants are scaled variants.
  const { aspectWidth, aspectHeight } = getFirstImageAspectRatio(images?.[0], imageVariants[0]);
  const [isCustomFullScreen, setCustomFullScreen] = useState(false);
  const [slideToImage, setSlideToImage] = useState(0);
  let items = images.map((img, i) => {
    return {
      // We will only use the image resource, but react-image-gallery
      // requires the `original` key from each item.
      original: '',
      alt: intl.formatMessage(
        { id: 'ListingImageGallery.imageAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbAlt: intl.formatMessage(
        { id: 'ListingImageGallery.imageThumbnailAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbnail: img.attributes?.variants?.[thumbVariants[0]],
      image: img,
    };
  });
  const imageSizesMaybe = isFullscreen
    ? {}
    : { sizes: `(max-width: 1024px) 100vw, (max-width: 1200px) calc(100vw - 192px), 708px` };
  const renderItem = item => {
    return (
      <AspectRatioWrapper
        width={aspectWidth || 1}
        height={aspectHeight || 1}
        className={isFullscreen ? css.itemWrapperFullscreen : css.itemWrapper}
      >
        <div className={css.itemCentering}>
          <ResponsiveImage
            rootClassName={css.item}
            image={item.image}
            alt={item.alt}
            variants={imageVariants}
            {...imageSizesMaybe}
          />
        </div>
      </AspectRatioWrapper>
    );
  };
  const renderThumbInner = item => {
    return (
      <div>
        <ResponsiveImage
          rootClassName={css.thumb}
          image={item.image}
          alt={item.thumbAlt}
          variants={thumbVariants}
          sizes="88px"
        />
      </div>
    );
  };

  const onScreenChange = isFull => {
    setIsFullscreen(isFull);
  };

  const renderLeftNav = (onClick, disabled) => {
    return (
      <button className={css.navLeft} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <IconArrowHead direction="left" size="big" />
        </div>
      </button>
    );
  };
  const renderRightNav = (onClick, disabled) => {
    return (
      <button className={css.navRight} disabled={disabled} onClick={onClick}>
        <div className={css.navArrowWrapper}>
          <IconArrowHead direction="right" size="big" />
        </div>
      </button>
    );
  };
  const RenderFullscreenButton = onClick => {
    return isFullscreen ? (
      <Button
        onClick={onClick}
        rootClassName={css.close}
        title={intl.formatMessage({ id: 'ListingImageGallery.closeModalTitle' })}
      >
        <span className={css.closeText}>
          <FormattedMessage id="ListingImageGallery.closeModal" />
        </span>
        <IconClose rootClassName={css.closeIcon} />
      </Button>
    ) : (
      <></>
    );
  };

  if (items.length === 0) {
    return <ResponsiveImage className={css.noImage} image={null} variants={[]} alt="" />;
  }

  const classes = classNames(isFullscreen ? '' : css.reactImagesWrapperHidden, className);

  const handleFullScreenToggle = () => {
    if (galleryRef.current) {
      galleryRef.current.toggleFullScreen();
    }
  };

  let desktopGalleryClassname;
  let desktopGallerySmallClassname = css.desktopGallerySmall;

  const slicedItems = items.slice(0, 5);

  if (slicedItems.length < 3) {
    desktopGalleryClassname = classNames(css.desktopGallery, css.desktopGallery1);
  } else if (slicedItems.length < 5) {
    desktopGalleryClassname = classNames(css.desktopGallery, css.desktopGallery3);
    desktopGallerySmallClassname = classNames(css.desktopGallerySmall, css.desktopGallerySmall3);
  } else {
    desktopGalleryClassname = css.desktopGallery;
  }

  const openFullScreen = () => setCustomFullScreen(true);
  const closeFullScreen = () => setCustomFullScreen(false);

  return (
    <div className={css.galleryWrapper}>
      <ReactImageGallery
        ref={galleryRef}
        additionalClass={classes}
        items={items}
        renderItem={renderItem}
        renderThumbInner={renderThumbInner}
        onScreenChange={onScreenChange}
        renderLeftNav={renderLeftNav}
        renderRightNav={renderRightNav}
        renderFullscreenButton={RenderFullscreenButton}
        showFullscreenButton={false}
        useBrowserFullscreen={false}
        {...IMAGE_GALLERY_OPTIONS}
      />
      <div className={desktopGalleryClassname}>
        <div
          className={css.desktopGalleryLarge}
          onClick={() => {
            setSlideToImage(0);
            openFullScreen();
          }}
        >
          <img
            className={css.desktopGalleryLargeImg}
            src={slicedItems[0]?.image?.attributes?.variants?.['scaled-large']?.url}
            alt="Large"
          />
          <div className={css.desktopGalleryLargeImgOverlay} />
        </div>
        {slicedItems.length > 2 && (
          <div className={desktopGallerySmallClassname}>
            {slicedItems.length > 1 &&
              slicedItems.slice(1).map((item, index) => (
                <div
                  key={index}
                  className={classNames(
                    css.desktopGallerySmallItem,
                    css[`desktopGallerySmallItem${index + 1}`]
                  )}
                  onClick={() => {
                    setSlideToImage(index + 1);
                    openFullScreen();
                  }}
                >
                  {item?.image ? (
                    <>
                      <img
                        className={css.desktopGallerySmallItemImg}
                        src={item?.image?.attributes?.variants?.['scaled-medium']?.url}
                        alt={`Small ${index + 1}`}
                      />
                      <div
                        className={classNames(
                          css.desktopGallerySmallItemImgOverlay,
                          css[`desktopGallerySmallItemImgOverlay${index + 1}`]
                        )}
                      />
                    </>
                  ) : (
                    <ResponsiveImage
                      className={classNames(css.noImage, css.desktopGallerySmallItemImg)}
                      image={null}
                      variants={[]}
                      alt=""
                    />
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
      <button
        className={css.openFullscreen}
        onClick={() => {
          setSlideToImage(0);
          openFullScreen();
        }}
      >
        <FormattedMessage
          id="ListingImageGallery.viewImagesButton"
          values={{ count: images.length }}
        />
      </button>
      {isCustomFullScreen && (
        <div className={css.customFullscreen}>
          <button className={css.closeBtn} onClick={closeFullScreen}>
            <IconClose rootClassName={css.closeBtnIcon} />
          </button>
          <ReactImageGallery
            ref={galleryRef}
            additionalClass={css.fullScreenImage}
            items={items}
            showFullscreenButton={false}
            renderItem={renderItem}
            showThumbnails={false}
            renderThumbInner={renderThumbInner}
            onScreenChange={onScreenChange}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
            startIndex={slideToImage}
            {...IMAGE_GALLERY_OPTIONS}
          />
        </div>
      )}
    </div>
  );
};

ListingImageGallery.defaultProps = {
  rootClassName: null,
  className: null,
  thumbnailVariants: null,
};

const { string, arrayOf } = PropTypes;

ListingImageGallery.propTypes = {
  rootClassName: string,
  className: string,
  images: arrayOf(propTypes.image).isRequired,
  imageVariants: arrayOf(string).isRequired,
  thumbnailVariants: arrayOf(string),

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(ListingImageGallery);
