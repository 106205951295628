import React from 'react';

import css from './listingCharacterstics.module.css';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withViewport } from '../../util/uiHelpers';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import icons from './assets';

const getFormattedData = (publicData, intl) => {
  const data = [];
  if (publicData?.tower_powerconnection?.length) {
    data.push({
      heading: intl.formatMessage({ id: 'ListingPage.energySupply' }),
      options: [
        {
          key: 'tower_powerconnection_230',
          text: intl.formatMessage({ id: 'ListingPage.towerPowerconn230' }),
          show: publicData?.tower_powerconnection?.includes('tower_powerconnection_230'),
        },
        {
          key: 'tower_powerconnection_emergency',
          text: intl.formatMessage({ id: 'ListingPage.towerPowerconnEmergency' }),
          show: publicData?.tower_powerconnection?.includes('tower_powerconnection_emergency'),
        },
        {
          key: 'tower_powerconnection_autarkic',
          text: intl.formatMessage({ id: 'ListingPage.towerPowerconnAutarkic' }),
          show: publicData?.tower_powerconnection?.includes('tower_powerconnection_autarkic'),
        },
      ],
    });
  }
  if (publicData?.tower_connectivity?.length) {
    data.push({
      heading: intl.formatMessage({ id: 'ListingPage.towerConnectivity' }),
      options: [
        {
          key: 'tower_connectivity_internet_4g',
          text: intl.formatMessage({ id: 'ListingPage.towerConnInternet4g' }),
          show: publicData?.tower_connectivity?.includes('tower_connectivity_internet_4g'),
        },
        {
          key: 'tower_connectivity_internet_5g',
          text: intl.formatMessage({ id: 'ListingPage.towerConnInternet5g' }),
          show: publicData?.tower_connectivity?.includes('tower_connectivity_internet_5g'),
        },
        {
          key: 'tower_connectivity_selfmgt',
          text: intl.formatMessage({ id: 'ListingPage.towerConnSelfmgt' }),
          show: publicData?.tower_connectivity?.includes('tower_connectivity_selfmgt'),
        },
        {
          key: 'tower_connectivity_liveview',
          text: intl.formatMessage({ id: 'ListingPage.towerConnLiveview' }),
          show: publicData?.tower_connectivity?.includes('tower_connectivity_liveview'),
        },
      ],
    });
  }
  if (
    publicData?.tower_camera_bispectral_amount ||
    publicData?.tower_camera_optical_amount ||
    publicData?.tower_camera_thermal_amount
  ) {
    data.push({
      heading: intl.formatMessage({ id: 'ListingPage.cameras' }),
      options: [
        {
          key: 'tower_camera_optical_amount',
          text: intl.formatMessage({ id: 'ListingPage.towerCameraOptical' }),
          show: !!publicData?.tower_camera_optical_amount,
          value: publicData?.tower_camera_optical_amount,
        },
        {
          key: 'tower_camera_thermal_amount',
          text: intl.formatMessage({ id: 'ListingPage.towerCameraThermal' }),
          show: !!publicData?.tower_camera_thermal_amount,
          value: publicData?.tower_camera_thermal_amount,
        },
        {
          key: 'tower_camera_bispectral_amount',
          text: intl.formatMessage({ id: 'ListingPage.towerCameraBispectral' }),
          show: !!publicData?.tower_camera_bispectral_amount,
          value: publicData?.tower_camera_bispectral_amount,
        },
      ],
    });
  }
  if (publicData?.protection?.length || publicData?.protection_range) {
    data.push({
      heading: intl.formatMessage({ id: 'ListingPage.protection' }),
      options: [
        {
          key: 'protection_speaker',
          text: intl.formatMessage({ id: 'ListingPage.protectionSpeaker' }),
          show: publicData?.protection?.includes('protection_speaker'),
        },
        {
          key: 'protection_range',
          text: intl.formatMessage({ id: 'ListingPage.protectionRange' }),
          show: !!publicData?.protection_range,
          value: publicData?.protection_range + ' m',
        },
        {
          key: 'protection_operationcenter',
          text: intl.formatMessage({ id: 'ListingPage.protectionOperationcenter' }),
          show: publicData?.protection?.includes('protection_operationcenter'),
        },
      ],
    });
  }
  if (publicData?.tower_sabotage?.length) {
    data.push({
      heading: intl.formatMessage({ id: 'ListingPage.towerSabotage' }),
      options: [
        {
          key: 'tower_sabotage_protection',
          text: intl.formatMessage({ id: 'ListingPage.towerSabotageProtection' }),
          show: publicData?.tower_sabotage?.includes('tower_sabotage_protection'),
        },
        {
          key: 'tower_sabotage_camera',
          text: intl.formatMessage({ id: 'ListingPage.towerSabotageCamera' }),
          show: publicData?.tower_sabotage?.includes('tower_sabotage_camera'),
        },
      ],
    });
  }
  if (
    publicData?.dimensions_size_operation ||
    publicData?.dimensions_size_transport ||
    publicData?.dimensions_weight
  ) {
    data.push({
      heading: intl.formatMessage({ id: 'ListingPage.dimensionsWeightHeading' }),
      fullWidth: true,
      options: [
        {
          key: 'dimensions_size_transport',
          text: intl.formatMessage({ id: 'ListingPage.dimensionsSizeTransport' }),
          show: !!publicData?.dimensions_size_transport,
          value: publicData?.dimensions_size_transport,
        },
        {
          key: 'dimensions_size_operation',
          text: intl.formatMessage({ id: 'ListingPage.dimensionsSizeOperation' }),
          show: !!publicData?.dimensions_size_operation,
          value: publicData?.dimensions_size_operation,
        },
        {
          key: 'dimensions_weight',
          text: intl.formatMessage({ id: 'ListingPage.dimensionsWeight' }),
          show: !!publicData?.dimensions_weight,
          value: publicData?.dimensions_weight + ' kg',
        },
      ],
    });
  }
  return data;
};

const IconCheck = () => {
  return (
    <svg width="9" height="9" xmlns="http://www.w3.org/2000/svg">
      <path
        className={css.marketplaceFill}
        d="M2.636621 7.7824771L.3573694 5.6447948c-.4764924-.4739011-.4764924-1.2418639 0-1.7181952.4777142-.473901 1.251098-.473901 1.7288122 0l1.260291 1.1254783L6.1721653.505847C6.565577-.0373166 7.326743-.1636902 7.8777637.227582c.5473554.3912721.6731983 1.150729.2797866 1.6951076L4.4924979 7.631801c-.2199195.306213-.5803433.5067096-.9920816.5067096-.3225487 0-.6328797-.1263736-.8637952-.3560334z"
        fillRule="evenodd"
      />
    </svg>
  );
};

function ListingCharactersticsCard({ heading = '', options = [], fullWidth = false }) {
  return (
    <div style={{minWidth: fullWidth ? '100%' : ''}} className={css.listingCharactersticsCard}>
      <div className={css.listingCharactersticsCardHeading}>{heading}</div>
      {options.map(({ text = '', value = '', key, show }) => {
        if (!show) return <></>;
        const Icon = icons?.[key];
        return (
          <div className={css.listingCharactersticsCardItem}>
            <div className={css.listingCharactersticsCardIcon}>
              {Icon ? <Icon /> : <IconCheck />}
            </div>
            <p className={css.listingCharactersticsCardText}>{text}</p>
            <p className={css.listingCharactersticsCardValue}>{value}</p>
          </div>
        );
      })}
    </div>
  );
}

function ListingCharactersticsComponent({ publicData, intl }) {
  const data = getFormattedData(publicData, intl);
  return (
    <div className={css.listingCharactersticsWrapper}>
      <div className={css.listingCharactersticsHeading}>
        <FormattedMessage id="ListingPage.orderTitle" values={{ title: 'Details' }} />
      </div>
      <div className={css.listingCharactersticsContainer}>
        {data.map(({ heading, options, fullWidth }) => (
          <ListingCharactersticsCard fullWidth={fullWidth} heading={heading} options={options} />
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const { currentUser } = state.user;

  return {
    isAuthenticated,
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};
const mapDispatchToProps = dispatch => ({
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
});

const ListingCharacterstics = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withViewport,
  injectIntl,
  withRouter
)(ListingCharactersticsComponent);

export default ListingCharacterstics;
